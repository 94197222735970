<template>
  <fieldset 
    class="w-full mb-4"
  >
    <section class="w-full flex mb-6">
      <div class="w-1/4" />
      <h2 
        class="text-gray-900 text-xl md:text-2xl w-full text-right px-6 border-b-8"
        :class="[loading ? 'border-blue-light pulse' : 'border-blue-medium']"
      >
        <span
          v-if="loading"
          class="bg-blue-lightest block h-8 w-full mb-1"
        />
        <span
          v-else
        >{{ title }}</span>
      </h2>
    </section>
          
    <div class="pr-8 xl:pr-24">
      <span v-if="loading">
        <SelectInput
          v-for="i in Array(3)"
          :key="i"
          :loading-options="loading"
          :options="[]"
          theme="base"
        >
          <template #message />
        </SelectInput>
      </span>
      <slot v-else />
    </div>
  </fieldset>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    title: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
})
</script>
