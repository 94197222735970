
import { defineComponent, toRefs, watchEffect, ref, computed } from 'vue'
import UsersAPI from '@/apis/rota-architect/users'
import { staffUpdateSchema, adminUpdateSchema } from '@/schemas/user'

// Store
import store from '@/store'
import { GetterTypes as UserGetters } from '@/store/user/getters'
import { ActionTypes as ToastActions } from '@/store/toast'

// Types
import { ToastNotification } from '@/types/toast'
import { UserPreferences, UserSuccessResponse } from '@/types/users'

// Form
import { useForm } from 'vee-validate'

// Components
import StaffFieldset from '@/components/staff/layouts/StaffFieldset.vue'

// Hooks
import { useRouter } from 'vue-router'
import useToasts from '@/hooks/useToasts'
import useRole from '@/hooks/useRole'
import useSelectOptions from '@/hooks/useSelectOptions'

// Utils
import parseErrorMap from '@/utils/parseErrorMap'

export default defineComponent({
  components: {
    StaffFieldset,
  },

  props: {
    userId: { // from vue-router
      type: String,
      required: true,
    },
  },

  setup (props) {
    const dispatch = useToasts()
    const { isStaff, isAdmin } = useRole()
    const router = useRouter()

    // Form state
    const working = ref(false)
    const submissionError = ref('')
  
    // Form rendering
    const isArchitect = ref(false)

    const toggleIsArchitect = () => {
      isArchitect.value = !isArchitect.value
    }

    // Fetch current user details
    const { userId } = toRefs(props)
    const user = ref<UserSuccessResponse & UserPreferences | null>(null)
    const loadingUser = ref(true)
    
    watchEffect(() => {
      UsersAPI.show(userId.value)
        .then((res) => {
          user.value = res.data
          isArchitect.value = res.data.sub_grade_id === null
        })
        .catch((err) => {
          dispatch.errorToast(parseErrorMap(err.response.data))
        })
        .finally(() => {
          loadingUser.value = false
        })
    })

    // Set up page meta
    const isOwner = computed(() => props.userId === store.getters[UserGetters.USER_ID].toString())

    const pageHeading = computed(() => {
      if (loadingUser.value || !user.value) return '...'

      return isOwner.value ? 'Editing Your Profile' : `Editing - ${user.value.name}`
    })

    const backButtonConfig = computed(() => {
      if (loadingUser.value || !user.value) return null

      return isOwner.value ? {
        to: { name: 'my-profile'},
        label: 'To Profile',
      } : {
        to: { name: 'view-staff', params: { userId: user.value.id }},
        label: 'To Staff View',
      }
    })

    // Init form
    const { handleSubmit, values } = useForm({
      validationSchema:  isStaff.value ? staffUpdateSchema : adminUpdateSchema,
    })

    // Set up admin only fields
    let adminOnly = {}
    if (isAdmin.value) {
      const { 
        options: subGradeOptions, 
        loading: loadingSubGradeOptions,
      } = useSelectOptions('SubGrade')

      const { 
        options: siteOptions, 
        loading: loadingSiteOptions,
      } = useSelectOptions('Site')

      const { 
        options: rotaOptions, 
        loading: loadingRotaOptions,
      } = useSelectOptions('Rota')

      const { 
        options: contractOptions, 
        loading: loadingContractOptions,
      } = useSelectOptions('Contract')

      adminOnly = {
        subGradeOptions,
        loadingSubGradeOptions,
        siteOptions,
        loadingSiteOptions,
        rotaOptions,
        loadingRotaOptions,
        contractOptions,
        loadingContractOptions,
      }
    }

    const onSubmit = handleSubmit((values) => {
      if (working.value || loadingUser.value ||!user.value) return

      if (isAdmin.value) {
         if (isArchitect.value) {
          values.sub_grade_id = null
          values.rotas = []
        } else {
            if (values.primary_rota_id) values.rotas = [+values.primary_rota_id]
        }
      }

      if (user.value.email === values?.email) delete values.email

      submissionError.value = ''
      working.value = true

      UsersAPI.update(props.userId, values)
        .then(() => {
          const to = 
            isOwner.value
              ? {
                name: 'my-profile',
              }
              : {
                name: 'view-staff',
                params: { userId: props.userId },
              }
          router.push(to)
          dispatch.successToast('Details updated successfully.')
        })
        .catch((err) => {
          submissionError.value = parseErrorMap(err.response.data) || 'Bad request'
        })
        .finally(() => {
          working.value = false
        })
    })

    return {
      values,
      backButtonConfig,
      pageHeading,
      working,
      submissionError,
      onSubmit,
      loadingUser,
      user,
      isAdmin,
      isArchitect,
      toggleIsArchitect,
      ...adminOnly,
    }
  },

  /**
   * Check user has permission
   */
  beforeRouteEnter(to, _, next) {
    // role not check in global auth guards, to allow OR check with isOwner
    if (
      ['admin', 'super'].includes(store.getters[UserGetters.ROLE])
      || +to.params?.userId === +store.getters[UserGetters.USER_ID] // isOwner
    ) {
      next()
      return
    }

    store.dispatch(ToastActions.SHOW, {
      type: 'warning',
      heading: 'Unauthorized',
      content: 'You do not have permission to perform this action.',
    } as ToastNotification)

    next({ name: 'dashboard' })
  },
})
