import * as yup from 'yup'
import moment from 'moment'
import { Creatable, Updateable } from '@/types/users'

export const userCreateSchema: yup.ObjectSchema<Creatable> = yup.object({
  first_name: yup
    .string()
    .min(2, 'First name must be a minimum of 2 characters.')
    .max(50, 'First name must be a maximum of 50 characters.')
    .matches(/^[a-z ,.'-]+$/i, 'This is not a valid character. Valid characters include A-Z a-z ,.- and spaces.')
    .required('Please provide a first name.'),
  last_name: yup
    .string()
    .min(2, 'Last name must be a minimum of 2 characters.')
    .max(50, 'Last name must be a maximum of 50 characters.')
    .matches(/^[a-z ,.'-]+$/i, 'This is not a valid character. Valid characters include A-Z a-z ,.- and spaces.')
    .required('Please provide a last name.'),
  dob: yup
    .string()
    .matches(
      /^(19|20)\d\d[- /.](0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])$/,
      'Please enter a valid date of birth.',
    )
    .test(
      'no-under-16s',
      'Staff must be at least 16 years old.',
      (dob) => {
        const birthday = moment(dob)
        return moment().diff(birthday, 'years') >= 16
      },
    )
    .test(
      'no-over-100s',
      'Staff is over 100 years old!',
      (dob) => {
        const birthday = moment(dob)
        return moment().diff(birthday, 'years') <= 100
      },
    )
    .required('Please provide a date of birth.'),
  phone: yup
    .string()
    .min(9, 'Phone number must be a minimum of 9 characters.')
    .required('Please provide a phone number.'),
  email: yup
    .string()
    .email('Please provide a valid email.')
    .max(100, 'Please use an email that\'s fewer than 100 characters.')
    .required('Please provide a valid email.'),
  primary_rota_id: yup
    .string()
    .required('Please select a rota.'),
  sub_grade_id: yup
    .string()
    .required('Please select a grade.'),
  contract_id: yup
    .string()
    .required('Please complete this field.'),
  contract_value: yup
    .string()
    .required('Please complete this field.'),
  annual_leave: yup
    .string(),
  study_leave: yup
    .string(),
  parental_leave: yup
    .string(),
}).defined()


export const staffUpdateSchema: yup.ObjectSchema<Updateable> = yup.object({
  first_name: yup
    .string()
    .min(2, 'First name must be a minimum of 2 characters.')
    .max(50, 'First name must be a maximum of 50 characters.')
    .matches(/^[a-z ,.'-]+$/i, 'This is not a valid character. Valid characters include A-Z a-z ,.- and spaces.')
    .required('Please provide a first name.'),
  last_name: yup
    .string()
    .min(2, 'Last name must be a minimum of 2 characters.')
    .max(50, 'Last name must be a maximum of 50 characters.')
    .matches(/^[a-z ,.'-]+$/i, 'This is not a valid character. Valid characters include A-Z a-z ,.- and spaces.')
    .required('Please provide a last name.'),
  dob: yup
    .string()
    .matches(
      /^(19|20)\d\d[- /.](0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])$/,
      'Please enter a valid date of birth.',
    )
    .test(
      'no-under-16s',
      'Staff must be at least 16 years old.',
      (dob) => {
        const birthday = moment(dob)
        return moment().diff(birthday, 'years') >= 16
      },
    )
    .test(
      'no-over-100s',
      'Staff is over 100 years old!',
      (dob) => {
        const birthday = moment(dob)
        return moment().diff(birthday, 'years') <= 100
      },
    )
    .required('Please provide a date of birth.'),
  phone: yup
    .string()
    .min(9, 'Phone number must be a minimum of 9 characters.')
    .required('Please provide a phone number.'),
  email: yup
    .string()
    .email('Please provide a valid email.')
    .max(100, 'Please use an email that\'s fewer than 100 characters.')
    .required('Please provide a valid email.'),
}).defined()


export const adminUpdateSchema: yup.ObjectSchema<Updateable> = yup.object({
  first_name: yup
    .string()
    .min(2, 'First name must be a minimum of 2 characters.')
    .max(50, 'First name must be a maximum of 50 characters.')
    .matches(/^[a-z ,.'-]+$/i, 'This is not a valid character. Valid characters include A-Z a-z ,.- and spaces.')
    .required('Please provide a first name.'),
  last_name: yup
    .string()
    .min(2, 'Last name must be a minimum of 2 characters.')
    .max(50, 'Last name must be a maximum of 50 characters.')
    .matches(/^[a-z ,.'-]+$/i, 'This is not a valid character. Valid characters include A-Z a-z ,.- and spaces.')
    .required('Please provide a last name.'),
  dob: yup
    .string()
    .matches(
      /^(19|20)\d\d[- /.](0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])$/,
      'Please enter a valid date of birth.',
    )
    .test(
      'no-under-16s',
      'Staff must be at least 16 years old.',
      (dob) => {
        const birthday = moment(dob)
        return moment().diff(birthday, 'years') >= 16
      },
    )
    .test(
      'no-over-100s',
      'Staff is over 100 years old!',
      (dob) => {
        const birthday = moment(dob)
        return moment().diff(birthday, 'years') <= 100
      },
    )
    .required('Please provide a date of birth.'),
  phone: yup
    .string()
    .min(9, 'Phone number must be a minimum of 9 characters.')
    .required('Please provide a phone number.'),
  email: yup
    .string()
    .email('Please provide a valid email.')
    .max(100, 'Please use an email that\'s fewer than 100 characters.')
    .required('Please provide a valid email.'),
  primary_rota_id: yup
    .string()
    .required('Please select a rota.'),
  sub_grade_id: yup
    .string()
    .required('Please select a grade.'),
  contract_id: yup
    .string()
    .required('Please complete this field.'),
  contract_value: yup
    .string()
    .required('Please complete this field.'),
  annual_leave: yup
    .string(),
  study_leave: yup
    .string(),
  parental_leave: yup
    .string(),
}).defined()